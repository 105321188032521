<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
              :to="{ name: 'Reseller Page' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("PACKAGES.PACKAGES_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="../../../../../public/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.RESELLER_MODULE") }}</span>
              </li>
              <li>
                <span>{{ $t("PACKAGES.PACKAGES_LIST") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openPackageCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_PACKAGES)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("PACKAGES.ADD_PACKAGE") }}
            </span>
          </base-button>
        </div>
      </div>

      <package-list-table
        @onViewPackage="openPackageViewModal"
        @onEditPackage="openPackageEditModal"
        @onDeletePackage="deletePackage"
        @onAddPackage="openPackageCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewPackageModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewPackageModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PACKAGE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closePackageModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("PACKAGES.VIEW_PACKAGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-package-page
              v-if="openPackage"
              :packageId="openPackage.id"
              @onEditPackage="openPackageEditModal"
              @onDeletePackage="deletePackage"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditPackageModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditPackageModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'PACKAGE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="openPackageViewModal(openPackage)">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("PACKAGES.EDIT_PACKAGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-package-page
              v-if="openPackage"
              :packageId="openPackage.id"
              @onViewPackage="openPackageViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddPackageModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddPackageModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'PACKAGE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closePackageModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("PACKAGES.ADD_PACKAGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-package-page
              @onViewPackage="openPackageViewModal"
              @onClosePackageModal="closePackageModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
// import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PackageListTable from "./partials/PackageListTable.vue";
import EditPackagePage from "./components/EditPackageComponent.vue";
import AddPackagePage from "./components/AddPackageComponent.vue";
import ViewPackagePage from "./components/ViewPackageComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";

export default {
  layout: "DashboardLayout",

  components: {
    WrapperExpandButton,
    PackageListTable,
    // NotificationSubscription,
    EditPackagePage,
    AddPackagePage,
    ViewPackagePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPackageModalOpened = false;
    let isEditPackageModalOpened = false;
    let isAddPackageModalOpened = false;
    let openPackage = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPackageModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPackageModalOpened = true;
      }
      openPackage = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPackageModalOpened = true;
    }
    return {
      isViewPackageModalOpened: isViewPackageModalOpened,
      isEditPackageModalOpened: isEditPackageModalOpened,
      isAddPackageModalOpened: isAddPackageModalOpened,
      openPackage: openPackage,
      renderKey: 1,
    };
  },

  methods: {
    openPackageCreateModal() {
      this.closePackageModal();
      this.isAddPackageModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Packages",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openPackageViewModal(pack, reRender = false) {
      this.closePackageModal();
      this.openPackage = pack;
      this.isViewPackageModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Packages",
          query: { id: this.openPackage.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openPackageEditModal(pack) {
      this.closePackageModal();
      this.openPackage = pack;
      this.isEditPackageModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Packages",
          query: { id: this.openPackage.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closePackageModal() {
      this.isAddPackageModalOpened = false;
      this.isViewPackageModalOpened = false;
      this.isEditPackageModalOpened = false;
      this.openPackage = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Packages",
          query: {},
        }).href
      );
    },

    async deletePackage(pack) {
      const confirmation = await swal.fire({
        text: this.$t("PACKAGES.DELETE_THIS_PACKAGE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("packages/destroy", pack.id);
          this.renderKey++;
          this.closePackageModal();
          this.$notify({
            type: "success",
            message: this.$t("PACKAGES.PACKAGE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
