<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!pack">
      <span class="loader"></span>
    </span>
    <div v-if="pack" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("COMMON.PACKAGE_DETAILS") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img
                  src="../../../../../../public/img/kw-home.svg"
                  alt="icon"
                />
                <span>{{ $t("PACKAGES.PACKAGES_LIST") }}</span>
              </li>
              <li>
<<<<<<< HEAD
                <span>{{ pack.name }}</span>
=======
                <span>{{ `${pack.name}` }}</span>
>>>>>>> dc1de62060c5e828c8a75bf7f6057f296e3c2dd5
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button class="delete" @click="deletePackage">
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE") }}</span>
          </button>
          <button class="edit" @click="editPackage">
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav kw-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("COMMON.GENERAL_INFORMATIONS") }}
          </span>
          <package-view-global :pack="pack" />
        </tab-pane>

        <!-- <tab-pane title="gallery" id="2">
          <span slot="title">
            {{ $t("COMMON.GALLERY") }}
          </span>
          <package-view-gallery :pack="pack" />
        </tab-pane> -->

        <tab-pane
          title="authorizations"
          id="3"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PERMISSIONS)"
        >
          <span slot="title">
            {{ $t("USERS.AUTHORIZATIONS") }}
          </span>
          <package-view-authorizations :pack="pack" />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            {{ $t("COMMON.LOGS") }}
          </span>
          <package-view-logs :pack="pack" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import PackageViewGlobal from "../partials/PackageViewGlobal.vue";
import PackageViewLogs from "../partials/PackageViewLogs.vue";
// import PackageViewGallery from "../partials/PackageViewGallery.vue";
import PackageViewAuthorizations from "../partials/PackageViewAuthorizations.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    PackageViewGlobal,
    // PackageViewGallery,
    PackageViewLogs,
    PackageViewAuthorizations,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      pack: null,
    };
  },

  computed: {},

  watch: {},

  props: {
    packageId: {
      type: [String, Number],
      required: true,
    },
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("packages/get", this.packageId);
        this.pack = this.$store.getters["packages/package"];
        console.log(this.pack);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editPackage() {
      this.$emit("onEditPackage", this.pack);
    },

    deletePackage() {
      this.$emit("onDeletePackage", this.pack);
    },
  },
};
</script>
